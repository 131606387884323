$(document).ready(function(){
    //TRIGGER RESPONSIVE MENU
    $('#trigger').click(function(){
        if($(this).hasClass('open')){
            $(this).removeClass('open');
            $('header nav').slideUp();
        }else{
            $(this).addClass('open');
            $('header nav').slideDown();
        }
    });


    // BXSLIDER TESTIMONIALS
    $('.testisgrid').bxSlider({
        mode: 'horizontal',
        pager: false,
        controls: true,
    });


    //LEES MEER KNOP HOME OFFER
    $('p').each(function(){
        if ($(this).is(':empty')) {
            $(this).css('margin-bottom', 0);
        }
    });

    $('#offer li .readmorebutton').each(function(){
        $(this).closest('p').css('margin-bottom', 0);
    });
    $('#offer li .readmorebutton').click(function(){
        $(this).addClass('open', function(){
            $(this).closest('li').find('.readmore').slideDown();
        });
    });
    //LEES MINDER KNOP HOME OFFER
    $('#offer li .readlessbutton').click(function(){
        $(this).closest('li').find('.readmore').slideUp(function(){
            $(this).closest('li').find('.readmorebutton').removeClass('open');
        });
    });


    // TESTIMONIALS PAGE PACKERY
    $('.testimonialscatgrid').packery({
        itemSelector: '.testimonialscatgriditem',
        gutter: 20
    });
});
